<template>
  <layout-base>
    <loading-progress :loading="loading"></loading-progress>
    <div class="mt-1 min-h-screen flex flex-column">
      <div class="flex flex-column flex-auto">
        <div class="grid">
          <div class="col-12 lg:col-6">
            <!-- <div v-if="holders.length > 1" class="col-12 lg:col-6"> -->
            <div class="surface-card shadow-2 border-round h-full">
              <DataTable
                :value="holders"
                responsiveLayout="stack"
                class="p-datatable-sm flex justify-content-between flex-column h-full"
                v-model:selection="selectedHolder"
                selectionMode="single"
                dataKey="pk"
                @rowSelect="onRowSelect"
                stripedRows
              >
                <Column field="name" header="Псевдоним"></Column>
                <Column field="cards" header="Кол-во карт">
                  <template #body="slotProps">
                    {{ slotProps.data.cards.length }}
                  </template>
                </Column>
                <Column field="car" header="Кол-во машин">
                  <template #body="slotProps">
                    {{ slotProps.data.car != null ? slotProps.data.car : 0 }}
                  </template>
                </Column>
                <template #footer>
                  <Button
                    @click="
                      {
                        openDialog(), addHolder();
                      }
                    "
                    class="text-sm p-button-text p-0 flex m-auto"
                    icon="pi pi-plus"
                    iconPos="right"
                    label="Добавить держателя"
                  />
                </template>
              </DataTable>
            </div>
          </div>
          <div class="col-12 lg:col-6">
            <!-- <div class="col-12" :class="colClass()">  -->
            <div
              style="background-color: #eaeaea"
              class="dashboard-box border-round shadow-2 overflow-auto pt-0"
            >
              <div class="dashboard__title p-3 text-1xl font-normal">
                <p>Данные держателя</p>
                <div class="flex align-items-center">
                  <Button
                    class="text-sm p-button-text p-0 text-white"
                    iconPos="right"
                    icon="pi pi-pencil"
                    label="Редактировать"
                    @click="
                      {
                        openDialog(), editHolder();
                      }
                    "
                  />
                  <div class="p-2"></div>
                  <Button
                    class="text-sm p-button-rounded p-button-danger p-button-text p-0"
                    icon="pi pi-trash"
                    iconPos="right"
                    label="Удалить"
                    @click="delHolder"
                  />
                </div>
              </div>
              <div class="dashboard">
                <Card class="dashboard__card">
                  <template #content>
                    <DataTable
                      class="w-full editable-cells-table"
                      :value="selectedHolder"
                      responsiveLayout="stack"
                      breakpoint="1000"
                      showGridlines
                      editMode="cell"
                      @cell-edit-complete="onCellEditComplete"
                    >
                      <Column field="last_name" header="Фамилия"></Column>
                      <Column field="first_name" header="Имя"></Column>
                      <Column field="middle_name" header="Отчество"></Column>
                    </DataTable>
                  </template>
                  <template #footer>
                    <div class="mt-4" v-if="selectedCards.length">
                      <DataTable
                        :value="selectedCards"
                        responsiveLayout="stack"
                        breakpoint="9000px"
                        showGridlines
                      >
                        <Column field="number" header="Номер карты"></Column>
                        <Column field="card_type" header="Бренд">
                          <template #body="slotProps">
                            {{ slotProps.data.card_type_name }}
                          </template>
                        </Column>
                        <!-- <Column field="" header="">
                          <template #body="slotProps">
                            {{ slotProps.data.pk }}
                            <Button
                              class="
                                p-button p-component
                                dashboard__btn
                                w-full
                                justify-content-center
                              "
                            >
                              Отчет
                              <router-link
                                to="/trn"
                                v-ripple
                              ></router-link> </Button
                          ></template>
                        </Column> -->
                      </DataTable>
                    </div>
                  </template>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog modal="true" v-model:visible="display" dismissableMask="true">
      <template #header>
        <b class="text-xl mb-3">Добавить держателя</b>
      </template>
      <div class="px-4 pt-4 md:px-2 lg:px-2">
        <div class="grid">
          <div class="col-12 lg:col-2">
            <div class="text-900 font-medium text-xl mb-3">
              Данные держателя
            </div>
            <p class="m-0 p-0 text-600 line-height-3 mr-3">
              Введите данные держателя:
            </p>
          </div>
          <div class="col-12 lg:col-10">
            <div class="grid formgrid p-fluid">
              <div class="field mb-4 col-12 md:col-6">
                <label for="nickname" class="font-medium text-900"
                  >Псевдоним</label
                >
                <InputText id="nickname" v-model="dataUser.name" type="text" />
              </div>
              <div class="field mb-4 col-12 md:col-6">
                <label for="email" class="font-medium text-900">Фамилия</label>
                <InputText v-model="dataUser.last_name" type="text" />
              </div>
              <div class="field mb-4 col-12 md:col-6">
                <label for="state" class="font-medium text-900">Имя</label>
                <InputText type="text" v-model="dataUser.first_name" />
              </div>
              <div class="field mb-4 col-12 md:col-6">
                <label for="city" class="font-medium text-900">Отчество</label>
                <InputText type="text" v-model="dataUser.middle_name" />
              </div>
              <div class="col-12">
                <Button
                  @click="postHolder"
                  label="Сохранить"
                  class="w-auto mt-3"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  </layout-base>
</template>
<script>
import store from "@/store";
import config from "@/configs";
import { getHolders, postHolder } from "@/api/holders";
import FilterCompany from "../components/FilterCompany.vue";

export default {
  data() {
    return {
      config: config,
      companies: [],
      loading: true,
      holders: [].sort(),
      holdersCompanyList: [],
      selectedHolder: null,
      selectedCards: [],
      storeUserCurrent: null,
      // currentComp: store.state.curCompany ? store.state.curCompany : null,
      disabledCards: false,
      disabledCar: false,
      // curCompany: store.state.curCompany ? store.state.curCompany.id : null,
      display: false,
      dataUser: {
        action: "",
        pk: "",
        name: "",
        first_name: "",
        middle_name: "",
        last_name: "",
      },
    };
  },
  components: {
    FilterCompany,
  },
  computed: {
    curCompany() {
      getHolders(this);
      return store.state.company.curCompany;
    },
  },
  watch: {
    curCompany() {
      store.state.company.curCompany ? getHolders(this) : null;
      this.selectedCards = [];
    },
  },
  methods: {
    onRowSelect(event) {
      if (event.data) {
        this.selectedHolder = [event.data];
        this.selectedCards = event.data.cards;
      } else {
        this.selectedHolder = [event];
        this.selectedCards = event.cards;
      }
    },
    updateCompany(event) {
      store.commit("setCurCompany", event.value);
      getHolders(this);
    },
    openDialog() {
      this.clearUserData();
      this.display = true;
    },
    addHolder() {
      this.dataUser.action = "add";
    },
    postHolder() {
      if (this.dataUser.name.trim() != "") {
        this.loading = true;
        postHolder(this);
        this.display = false;
        // setTimeout(() => {
        //   this.holders = this.holders.sort(this.sortArray);
        // }, 300);
      } else {
        this.$toast.add({
          severity: "error",
          summary: `Пожалуйста заполните поле`,
          detail: "Поле 'Псевдоним' обязательно",
          life: 3000,
        });
      }
    },
    sortArray(x, y) {
      return y.name.localeCompare(x.name);
    },
    delHolder() {
      this.dataUser.action = "del";
      this.dataUser.pk = this.selectedHolder[0].pk;
      this.loading = true;
      postHolder(this);
      // getHolders(this);

      this.display = false;
    },
    editHolder() {
      this.dataUser.action = "edit";
      this.dataUser.pk = this.selectedHolder[0].pk;
      this.dataUser.name = this.selectedHolder[0].name;
      this.dataUser.first_name = this.selectedHolder[0].first_name;
      this.dataUser.last_name = this.selectedHolder[0].last_name;
      this.dataUser.middle_name = this.selectedHolder[0].middle_name;
    },
    clearUserData() {
      this.dataUser = {
        pk: "",
        // action: "",
        name: "",
        first_name: "",
        middle_name: "",
        last_name: "",
      };
    },
  },
  mounted() {
    // setTimeout(() => {
    //   getHolders(this);
    // }, 500);
    this.holders.length ? (this.loading = false) : (this.loading = false);
    window.scrollTo(0, 0);
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 500);
  },

  created() {
    if (window.location.hash === "#open") {
      this.display = true;
      this.dataUser.action = "add";
    }
    document.title = "Держатели | " + window.appBaseTitle;
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .p-dialog-header {
    justify-content: flex-end;
  }

  .p-dialog-mask.p-component-overlay.p-component-overlay-enter {
    padding: 0 15px !important;
  }

  .p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
    height: 40px;
  }

  .dashboard {
    background: #fff;

    &__card {
      width: 100% !important;
    }

    .p-card-body,
    .p-card-footer {
      padding: 0 !important;
    }

    &__title {
      height: 40px;
      font-weight: 500;
      //font-size: 22px;
      display: flex;
      justify-content: space-between !important;
      align-items: center;
      background-color: rgba(128, 128, 128, 0.395);
      color: var(--primaryTextColor2);
    }
  }

  .dashboard__btn {
    // margin: 8px 16px;
    margin: 8px auto;
    display: block;
    position: relative;
    text-align: center;
    // max-width: 290px;

    a {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
  .p-datatable.p-datatable-hoverable-rows
    .p-datatable-tbody
    > tr:not(.p-highlight):not(.blns-result-row):hover {
    background-color: #5a92f95e !important;
  }

  .p-datatable .p-datatable-tbody > tr:focus,
  .p-datatable .p-datatable-tbody > tr.p-highlight,
  .p-datatable .p-datatable-tbody > tr.p-highlight {
    outline: none !important;
    background-color: #5a92f95e !important;
  }
}
</style>