import api from "@/api/index";
import store from "@/store";
import { setEmoji } from "@/handlers/emoji";

export function getHolders(that) {
  let id = store.state.company.curCompany
    ? store.state.company.curCompany.id
    : null;

  if (id !== null) {
    api
      .get("holder/list", {
        params: {
          company: id,
        },
      })
      .then((r) => {
        console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
        that.holders = r.data;
        // that.currentComp = that.currentComp
        //   ? that.currentComp
        //   : that.holdersCompanyList[0].id;
        that.onRowSelect(that.holders[0]);
        // that.selectedHolder = [that.holders[0]]
        // that.selectedHolder[0].cards.length === 0 ? that.disabledCards = true : that.disabledCards = false
        // that.selectedHolder[0].car === null ? that.disabledCar = true : that.disabledCar = false
      })
      .catch((err) => {
        console.log(err);
        let statusCode = err.request.status;
        let statusText = err.request.statusText;
        console.error(setEmoji("error") + statusCode + " " + statusText);
        switch (statusCode) {
          case 0:
            that.$toast.add({
              severity: "warn",
              summary: `${setEmoji("error")} Нет связи с сервером`,
              detail: "Попробуйте позднее!",
            });
            break;
          case 403:
            that.$toast.add({
              severity: "warn",
              summary: `${setEmoji("lock")} Время сессии истекло`,
              detail: "Требуется авторизация",
              life: 5000,
            });
            that.$router.push("/login");
            break;
          case 404:
            that.$toast.add({
              severity: "error",
              summary: `${setEmoji("error")} Ошибка ${statusCode}`,
              detail: "Запрос не найден",
              life: 5000,
            });
            break;
          default:
            that.$toast.add({
              severity: "error",
              summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
              detail: "Попробуйте позднее!",
              life: 5000,
            });
            break;
        }
      })
      .finally(() => {
        that.loading = false;
      });
  }
}

export function postHolder(that) {
  let action = that.dataUser.action;
  let indexHolder =
    that.holders.length > 0
      ? that.holders.indexOf(that.selectedHolder[0])
      : null;
  let newHolder = {
    pk: that.dataUser.pk,
    name: that.dataUser.name,
    company: store.state.company.curCompany
      ? store.state.company.curCompany.id
      : "",
    first_name: that.dataUser.first_name,
    middle_name: that.dataUser.middle_name,
    last_name: that.dataUser.last_name,
    cards: [],
  };
  api
    .post("holder", {
      action: that.dataUser.action,
      company: store.state.company.curCompany
        ? store.state.company.curCompany.id
        : "",
      name: that.dataUser.name,
      first_name: that.dataUser.first_name,
      middle_name: that.dataUser.middle_name,
      last_name: that.dataUser.last_name,
      pk: that.dataUser.pk,
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      if (action === "add") {
        newHolder.pk = r.data.pk;
        that.holders.push(newHolder);
        that.onRowSelect(that.holders[that.holders.length - 1]);
        that.loading = false;
        that.$toast.add({
          severity: "success",
          summary: `Держатель ${newHolder.name}`,
          detail: `${setEmoji("access")} Успешно добавлен`,
          life: 1500,
        });
      } else if (action === "del") {
        console.log(indexHolder);
        that.holders.splice(indexHolder, 1);
        that.loading = false;
        that.$toast.add({
          severity: "success",
          summary: `Держатель`,
          detail: `${setEmoji("trash")}  Успешно удален`,
          life: 1500,
        });
        that.onRowSelect(that.holders[0]);
      } else if (action === "edit") {
        that.selectedHolder[0].name = that.dataUser.name;
        that.selectedHolder[0].first_name = that.dataUser.first_name;
        that.selectedHolder[0].last_name = that.dataUser.last_name;
        that.selectedHolder[0].middle_name = that.dataUser.middle_name;
        that.loading = false;
        that.$toast.add({
          severity: "success",
          summary: `Держатель`,
          detail: `${setEmoji("access")} Успешно отредактирован`,
          life: 1500,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    });
}
